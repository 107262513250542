/*
        This font software is the property of Commercial Type.

        You may not modify the font software, use it on another website, or install it on a computer.

        License information is available at http://commercialtype.com/eula
        For more information please visit Commercial Type at http://commercialtype.com or email us at info[at]commercialtype.com

        Copyright (C) 2020 Schwartzco Inc.
        License: 2006-QHUJRU     
*/

@font-face {
  font-family: "Graphik";
  src: url("/fonts/Graphik-SuperItalic-Web.woff2") format("woff2"),
    url("/fonts/Graphik-SuperItalic-Web.woff") format("woff");
  font-weight: 900;
  font-style: italic;
  font-stretch: normal;
  font-display: swap;
}

@font-face {
  font-family: "Graphik";
  src: url("/fonts/Graphik-Super-Web.woff2") format("woff2"), url("/fonts/Graphik-Super-Web.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
}

@font-face {
  font-family: "Graphik";
  src: url("/fonts/Graphik-BlackItalic-Web.woff2") format("woff2"),
    url("/fonts/Graphik-BlackItalic-Web.woff") format("woff");
  font-weight: 800;
  font-style: italic;
  font-stretch: normal;
  font-display: swap;
}

@font-face {
  font-family: "Graphik";
  src: url("/fonts/Graphik-Black-Web.woff2") format("woff2"), url("/fonts/Graphik-Black-Web.woff") format("woff");
  font-weight: 800;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
}

@font-face {
  font-family: "Graphik";
  src: url("/fonts/Graphik-BoldItalic-Web.woff2") format("woff2"),
    url("/fonts/Graphik-BoldItalic-Web.woff") format("woff");
  font-weight: 700;
  font-style: italic;
  font-stretch: normal;
  font-display: swap;
}

@font-face {
  font-family: "Graphik";
  src: url("/fonts/Graphik-Bold-Web.woff2") format("woff2"), url("/fonts/Graphik-Bold-Web.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
}

@font-face {
  font-family: "Graphik";
  src: url("/fonts/Graphik-SemiboldItalic-Web.woff2") format("woff2"),
    url("/fonts/Graphik-SemiboldItalic-Web.woff") format("woff");
  font-weight: 600;
  font-style: italic;
  font-stretch: normal;
  font-display: swap;
}

@font-face {
  font-family: "Graphik";
  src: url("/fonts/Graphik-Semibold-Web.woff2") format("woff2"), url("/fonts/Graphik-Semibold-Web.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
}

@font-face {
  font-family: "Graphik";
  src: url("/fonts/Graphik-MediumItalic-Web.woff2") format("woff2"),
    url("/fonts/Graphik-MediumItalic-Web.woff") format("woff");
  font-weight: 500;
  font-style: italic;
  font-stretch: normal;
  font-display: swap;
}

@font-face {
  font-family: "Graphik";
  src: url("/fonts/Graphik-Medium-Web.woff2") format("woff2"), url("/fonts/Graphik-Medium-Web.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
}

@font-face {
  font-family: "Graphik";
  src: url("/fonts/Graphik-RegularItalic-Web.woff2") format("woff2"),
    url("/fonts/Graphik-RegularItalic-Web.woff") format("woff");
  font-weight: 400;
  font-style: italic;
  font-stretch: normal;
  font-display: swap;
}

@font-face {
  font-family: "Graphik";
  src: url("/fonts/Graphik-Regular-Web.woff2") format("woff2"), url("/fonts/Graphik-Regular-Web.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
}

@font-face {
  font-family: "Graphik";
  src: url("/fonts/Graphik-LightItalic-Web.woff2") format("woff2"),
    url("/fonts/Graphik-LightItalic-Web.woff") format("woff");
  font-weight: 300;
  font-style: italic;
  font-stretch: normal;
  font-display: swap;
}

@font-face {
  font-family: "Graphik";
  src: url("/fonts/Graphik-Light-Web.woff2") format("woff2"), url("/fonts/Graphik-Light-Web.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
}

@font-face {
  font-family: "Graphik";
  src: url("/fonts/Graphik-ExtralightItalic-Web.woff2") format("woff2"),
    url("/fonts/Graphik-ExtralightItalic-Web.woff") format("woff");
  font-weight: 200;
  font-style: italic;
  font-stretch: normal;
  font-display: swap;
}

@font-face {
  font-family: "Graphik";
  src: url("/fonts/Graphik-Extralight-Web.woff2") format("woff2"),
    url("/fonts/Graphik-Extralight-Web.woff") format("woff");
  font-weight: 200;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
}

@font-face {
  font-family: "Graphik";
  src: url("/fonts/Graphik-ThinItalic-Web.woff2") format("woff2"),
    url("/fonts/Graphik-ThinItalic-Web.woff") format("woff");
  font-weight: 100;
  font-style: italic;
  font-stretch: normal;
  font-display: swap;
}

@font-face {
  font-family: "Graphik";
  src: url("/fonts/Graphik-Thin-Web.woff2") format("woff2"), url("/fonts/Graphik-Thin-Web.woff") format("woff");
  font-weight: 100;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
}

/*
This font software is the property of Commercial Type.

You may not modify the font software, use it on another website, or install it on a computer.

License information is available at http://commercialtype.com/eula
For more information please visit Commercial Type at http://commercialtype.com or email us at info[at]commercialtype.com

Copyright (C) 2020 Schwartzco Inc.
License: 2006-QHUJRU     
*/

*,
*::after,
*::before {
  box-sizing: border-box;
}

:root {
  font-size: 20px;
}

::selection {
  background: #000000;
  color: white;
}

body {
  margin: 0;
  padding: 0;
  /* background: #212028; */
  background: #1a1a21;
  overflow: hidden;
  font-family: "Graphik", sans-serif;
  color: rgba(255, 255, 255, 1);
  font-size: 0.95rem;
  line-height: 2;
  font-weight: 300;
}

h1 {
  font-size: 6vw;
  font-weight: 200;
  line-height: 1.2;
  color: #fff;
}

h1 em {
  font-size: 70%;
  display: block;
  color: rgba(255, 255, 255, 0.7);
  margin-top: 20px;
}

h1.large {
  font-size: 10vw;
}

h1.large a {
  position: relative;
}

h1.large a:before {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0%;
  height: 2px;
  background: #fff;
  content: "";
  transition: all 0.2s ease;
  -webkit-transition: all 0.3s ease;
}

h1.large a:hover:before {
  width: 100%;
}

h1 strong {
  font-weight: 500;
}

/* h2 {
font-size: 12px;
font-weight: 300;
line-height:1;
margin:0;
padding:0;
color:#fff;
text-transform: uppercase;
letter-spacing: 2px;
opacity:.4
} */

h2 {
  font-size: 54px;
  font-weight: 200;
  line-height: 1.3;
  color: rgba(255, 255, 255, 1);
  max-width: 400px;
}

h3 {
  font-size: 32px;
  font-weight: 200;
  line-height: 1.5;
  color: rgba(255, 255, 255, 0.7);
  max-width: 600px;
  width: 60vw;
}

h3 strong {
  color: #fff;
}

h3 u {
  /* color: #fff; */
  text-decoration: none;
}

h5 {
  font-size: 15vw;
  font-weight: 500;
  line-height: 1;
  margin: 0;
  padding: 0;
  color: #5857e7;
  opacity: 1;
}

h6 {
  /* position: absolute; */
  /* right:-40vw; */
  letter-spacing: 0.5px;
  /* top:20vh; */
  font-weight: 300;
  font-size: 15px;
  line-height: 1.6;
  color: rgba(255, 255, 255, 0.5);
}

.logo {
  display: block;
  position: absolute;
  left: 10vw;
  width: 130px;
  top: 7vw;
  margin-top: -3px;
  z-index: 999;
}

.logo img {
  width: auto;
  height: 22px;
  margin-left: 10px;
}

.logo .logo-wrap {
  width: 110px;
  height: 33px;
  overflow: hidden;
  margin-left: -10px;
}

img.rock {
  width: 70vw;
  height: auto;
}

img.rock_s {
  width: 60vw;
  height: auto;
  transform: rotate(100deg);
  -webkit-transform: rotate(100deg);
}

img.soty {
    width: 200px;
    height:auto;
    margin-top:50px;
}

strong {
  font-weight: 300;
}

h1 a {
  color: #fff;
  text-decoration: none;
  pointer-events: initial;
  /* border-bottom: 1px solid #fff; */
}

a {
  color: #fff;
}

.footer {
  display: flex;
  max-width: 900px;
  width: 70vw;
  pointer-events: none;
}

.footer .col {
  width: 30%;
  margin-right: 3%;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.6);
}

.footer .col strong {
  display: block;
}

.footer .col a {
  pointer-events: initial;
  color: #fff;
  text-decoration: none;
}

#root {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.loading {
  padding: 10px;
  transform: translate3d(-50%, -50%, 0);
  color: #828288;
  cursor: wait;
  position: fixed;
  width:100vw;
  height:100vh;
  
  top:0;
  left:0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loading_items .font1, .loading_items .font2, .loading_items .font3, .loading_items .font4 {
  color:#1a1a21;
}

.loading_items .font1 {
  font-weight: 500
}
.loading_items .font2 {
  font-weight: 400
}
.loading_items .font3 {
  font-weight: 300
}
.loading_items .font4 {
  font-weight: 200
}

.foreground {
  top: -10rem;
  left: -10rem;
  width: calc(100% + 20rem);
  height: calc(100% + 20rem);
  z-index: 999999;
  position: absolute;
  pointer-events: none;
  background-position: 50%;
  background-image: url(/img/noise.png);
  pointer-events: none;
  animation: foreground 1s steps(2) infinite;
}

@media screen and (max-width: 53em) {
  .foreground {
    display: none;
  }
}

@keyframes foreground {
	0% {
		transform: translate3d(0, 9rem, 0)
	}
	10% {
		transform: translate3d(-1rem, -4rem, 0)
	}
	20% {
		transform: translate3d(-8rem, 2rem, 0)
	}
	30% {
		transform: translate3d(9rem, -9rem, 0)
	}
	40% {
		transform: translate3d(-2rem, 7rem, 0)
	}
	50% {
		transform: translate3d(-9rem, -4rem, 0)
	}
	60% {
		transform: translate3d(2rem, 6rem, 0)
	}
	70% {
		transform: translate3d(7rem, -8rem, 0)
	}
	80% {
		transform: translate3d(-9rem, 1rem, 0)
	}
	90% {
		transform: translate3d(6rem, -5rem, 0)
	}
	to {
		transform: translate3d(-7rem, 0, 0)
	}
}

.scrollArea {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow: auto;
}

.frame {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  padding: 1.5rem;
  z-index: 2;
}

.frame__title {
  font-size: inherit;
  font-weight: normal;
  margin: 0;
}

.frame__links {
  margin: 1rem 0;
}

.frame__link:not(:last-child) {
  margin-right: 1rem;
}

.frame__nav .frame__link:first-child {
  margin-right: 1.5rem;
}

.bottom-left {
  padding: 1.5rem;
  transform: translate3d(0, -100%, 0) !important;
}

.canvas > div {
  /* z-index: 10000000; */
}

.canvas {
  z-index: 1;
  pointer-events: none;
}

@media screen and (max-width: 53em) {
  h1 {
    position: relative;
    left: 15vw;
    font-size: 12vw;
    width: 80vw;
  }
  img.rock {
    width: 110vw;
    height: auto;
    position: relative;
    top: 30vh;
    left: -10vw;
  }
  img.rock_s {
    width: 110vw;
    transform: rotate(100deg) translateX(80%) translateY(-50px);
    -webkit-transform: rotate(100deg) translateX(80%) translateY(-50px);
  }
  h3 {
    font-size: 24px;
    width: 80vw;
    position: relative;
    left: -22vw;
  }
  h2 {
    font-size: 36px;
    line-height: 1.5;
  }

  h5 {
    font-size: 5rem;
  }

  .footer {
    display: none;
  }

  h1.large {
    font-size: 20vw;
    width: 90vw;
  }
}

@media screen and (min-width: 53em) {
  body {
    overflow: hidden;
  }
  .frame {
    display: grid;
    height: 100vh;
    padding: 2rem 3.7rem;
    pointer-events: none;
    align-content: space-between;
    grid-template-columns: 50% 50%;
    grid-template-rows: auto auto auto;
    grid-template-areas:
      "title links"
      "... ..."
      "... nav";
  }
  .frame__title {
    grid-area: title;
  }
  .frame__links {
    grid-area: links;
    justify-self: end;
    margin: 0;
  }
  .frame__link {
    pointer-events: auto;
  }
  .frame__nav {
    grid-area: nav;
    justify-self: end;
  }
  .bottom-left {
    padding: 0 0 2rem 3.7rem;
  }
}

#fp-nav {
  display: none;
}
#fp-nav.active ul {
  opacity: 1;
}
#fp-nav ul {
  position: fixed;
  z-index: 100;
  margin-top: -32px;
  top: 50%;
  opacity: 0;
  -webkit-transform: translate3d(0, 0, 0);
  -webkit-transition: opacity 0.5s ease-in-out 0.3s;
  -moz-transition: opacity 0.5s ease-in-out 0.3s;
  -o-transition: opacity 0.5s ease-in-out 0.3s;
  transition: opacity 0.5s ease-in-out 0.3s;
}

.fp_overlay {
  position: fixed;
  z-index: 99;
  width: 100vw;
  height: 100vw;
  top: 0;
  right: 0;
  background: rgb(0, 0, 0);
  pointer-events: none;
  background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.9486169467787114) 90%);
  -webkit-transition: opacity 0.5s ease;
  -moz-transition: opacity 0.5s ease;
  -o-transition: opacity 0.5s ease;
  transition: opacity 0.5s ease;
  opacity: 0;
}

#fp-nav:hover .fp_overlay {
  opacity: 1;
}

#fp-nav ul li {
  display: block;
  width: 14px;
  height: 13px;
  margin: 24px 7px;
  position: relative;
}

#fp-nav ul li a {
  display: block;
  position: relative;
  z-index: 1;
  cursor: pointer;
  text-decoration: none;
  font-size: 0;
  line-height: 0;
  padding: 5px 0;
  width: auto;
  height: 20px;
  width: 30px;
}

#fp-nav ul li a svg {
  position: absolute;
  top: -5px;
  left: 0;
  -webkit-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  transform: rotate(-90deg);
}

#fp-nav ul li a svg circle,
.fp-slidesNav ul li a svg circle {
  fill: transparent;
  stroke: #fff;
  stroke-width: 1.5;
  stroke-dasharray: 500;
  stroke-dashoffset: 500;
  -webkit-transition: stroke-dasharray 0s ease 0.3s, opacity 0.3s ease;
  -o-transition: stroke-dasharray 0s ease 0.3s, opacity 0.3s ease;
  transition: stroke-dasharray 0s ease 0.3s, opacity 0.3s ease;
  opacity: 0;
}
#fp-nav ul li a.active svg circle,
#fp-nav ul li a:hover svg circle,
.fp-slidesNav ul li a.active svg circle,
.fp-slidesNav ul li a:hover svg circle {
  -webkit-transition: stroke-dasharray 0.6s ease, opacity 0s ease;
  -o-transition: stroke-dasharray 0.6s ease, opacity 0s ease;
  transition: stroke-dasharray 0.6s ease, opacity 0s ease;
  stroke-dasharray: 585;
  opacity: 1;
}

#fp-nav ul li a span {
  border-radius: 50%;
  position: absolute;
  z-index: 1;
  border: 0;
  background: rgba(255, 255, 255, 0.15);
  left: 50%;
  top: 50%;
  width: 6px;
  height: 6px;
  margin: -3px 0 0 -3px;
  -webkit-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}

#fp-nav ul {
  position: fixed;
  z-index: 9999999;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  top: 50%;
}

#fp-nav.right ul {
  right: 37px;
}

#fp-nav ul li .fp-tooltip {
  position: absolute;
  top: -2px;
  color: #fff;
  font-size: 16px;
  white-space: nowrap;

  overflow: hidden;
  display: block;
  width: auto;
  -webkit-transition: all 0.25s ease !important;
  -o-transition: all 0.25s ease !important;
  transition: all 0.25s ease !important;
  visibility: hidden;
  opacity: 0;
}

#fp-nav ul li .fp-tooltip.right {
  padding-right: 20px;
  right: 20px;
}

#fp-nav:hover ul li .fp-tooltip {
  opacity: 0.5;
  visibility: visible;
}

#fp-nav:hover ul li:hover .fp-tooltip {
  opacity: 1;
}

#fp-nav:hover span {
  background: rgba(255, 255, 255, 0.35);
}

#fp-nav ul li a.active span,
#fp-nav ul li a:hover span,
#fp-nav ul li:hover a.active span {
  background: #fff;
  -webkit-transition: all 0.25s ease-in-out;
  -o-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
